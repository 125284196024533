import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import { SnackbarProvider } from './context/SnackbarContext'
import { AuthProvider } from './context/AuthContext'
import { StripeProvider } from './context/StripeContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
// import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import { config } from './assets/config/config'

// Sentry.init({
//   dsn: "https://9801423c42c445709aa381645779a360@o1259622.ingest.sentry.io/6434607",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: .5,
//   environment: config.environment
// })

ReactDOM.render(
  <SidebarProvider>
    <SnackbarProvider>
      <StripeProvider>
        <AuthProvider>
          <Suspense fallback={<ThemedSuspense />}>
            <Windmill usePreferences>
              <App />
            </Windmill>
          </Suspense>
        </AuthProvider>
      </StripeProvider>
    </SnackbarProvider>
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
