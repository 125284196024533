const Joi = require("joi");

const envVarsSchema = Joi.object()
  .keys({
    NODE_ENV: Joi.string()
      .valid("production", "development", "demo", "test")
      .required(),
    REACT_APP_API_URL: Joi.string()
      .allow("")
      .default("")
      .description("backend server url"),
    REACT_APP_STRIPE_PUBLIC_KEY: Joi.string()
      .required()
      .description("Stripe public key"),
    REACT_APP_STRIPE_PUBLIC_TEST_KEY: Joi.string()
      .required()
      .description("Stripe public test key"),
    REACT_APP_USERS_PER_PAGE: Joi.number()
      .default(10)
      .description("number of users per page in users table"),
    REACT_APP_UNSIGNED_UPLOAD_PRESET: Joi.string()
      .required()
      .description('Cloudinary "unsigned upload preset"'),
    REACT_APP_CLOUD_NAME: Joi.string()
      .required()
      .description('Cloudinary "cloud name"'),
    REACT_APP_PAYG_PAYMENT_LINK: Joi.string()
      .required()
      .description("Stripe-Checkout Link (Pay-As-You-Go) "),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema
  .prefs({ errors: { label: "key" } })
  .validate(process.env);

if (error) {
  throw new Error(`Client Config validation error: ${error.message}`);
}

export const config = {
  api: {
    url: envVars.NODE_ENV === "production" ? envVars.REACT_APP_API_URL : "",
  },
  stripe: {
    publicKey:
      envVars.NODE_ENV === "production"
        ? envVars.REACT_APP_STRIPE_PUBLIC_KEY
        : envVars.REACT_APP_STRIPE_PUBLIC_TEST_KEY,
    payg_link:
      envVars.NODE_ENV === "production"
        ? envVars.REACT_APP_PAYG_PAYMENT_LINK
        : envVars.REACT_APP_PAYG_PAYMENT_LINK_TEST,
  },
  users: {
    resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  environment: envVars.NODE_ENV,
  cloudName: envVars.REACT_APP_CLOUD_NAME,
  uploadPreset: envVars.REACT_APP_UNSIGNED_UPLOAD_PRESET,
};
